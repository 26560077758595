import { faCoins } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import React from "react"
import Layout from "../components/layout"
import { StaticImage } from "gatsby-plugin-image"
import { Link } from "gatsby"
import Seo from "../components/seo"

const Invest = ({ location }) => {
  return (
    <Layout location={location}>
      <Seo title="不動産投資" description="株式会社ファーストの不動産投資" />
      <div className="container">
        <div
          style={{
            textAlign: "center",
            fontSize: "2rem",
            color: "gray",
            margin: "10px 0px",
          }}
        >
          <FontAwesomeIcon icon={faCoins} />
        </div>
        <h3 className="title is-4" style={{ color: "gray" }}>
          不動産投資
        </h3>
        <section className="section">
          <h4 className="title is-4">安心・納得の不動産投資</h4>
          <p>
            近年、老後の生活資金や副収入を目的として、不動産投資を始める方が増えています。
          </p>
          <p>
            「不動産投資は難しそう」と感じる方もいらっしゃると思いますが、株やFXと比べ投資方法はシンプルで、学びやすく実践しやすい特徴があります。
          </p>
          <p>
            また節税効果や生命保険の代わりになるなど、副次的な効果が見込めるのも不動産投資の魅力のひとつです。
          </p>
          <p className="block">
            当社は「何をしたらよいのかわからない」方でも、安心・納得して不動産投資をスタートできるよう、お手伝いを出来ればと思っております。
          </p>

          <div className="columns is-centered">
            <div className="column is-half">
              <StaticImage
                src="../images/flows/flow-invest.drawio.png"
                style={{ margin: "0 auto" }}
                placeholder="none"
                quality={90}
                alt=""
              />
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <ul className="is-check">
                <li>
                  <span>投資プラン提案</span>
                  <ul>
                    <li>資産状況やご要望に応じて、投資プランをご提案します</li>
                    <li>合わせて、融資計画のご相談も承ります</li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="column">
              <ul className="is-check">
                <li>
                  <span>投資物件購入</span>
                  <ul>
                    <li>投資用の物件を購入します</li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <div className="columns">
            <div className="column">
              <ul className="is-check">
                <li>
                  <span>賃貸管理</span>
                  <ul>
                    <li>投資プランに応じ、物件を貸し出します</li>
                    <li>家賃収入が、お客様の継続的な収入となります</li>
                  </ul>
                </li>
              </ul>
            </div>
            <div className="column">
              <ul className="is-check">
                <li>
                  <span>物件売却</span>
                  <ul>
                    <li>投資プランに応じ、物件を売却します</li>
                    <li>売却益が、お客様の収入となります</li>
                  </ul>
                </li>
              </ul>
            </div>
          </div>
          <p>なお、不動産投資はデメリットがないわけではありません。</p>
          <p>
            家賃収入を得る場合は空室や滞納によるリスクが、売却益を得る場合は価格の変動や金利上昇のリスクといったものが存在します。
          </p>
          <p>投資を成功させるためにも、まずは当社にご相談ください。</p>
          <Link
            to="/contact"
            className="button is-dark is-fullwidth my-4 is-size-5"
            style={{ height: "4em" }}
          >
            お問い合わせはこちら　＞
          </Link>
        </section>
      </div>
    </Layout>
  )
}

export default Invest
